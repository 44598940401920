import React, { useState, useEffect } from "react";
import "./journal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { API } from "../../const";
import Image from '../../images/book.png'
export default function JournalContent() {
  window.scrollTo(0, 0);
  const [journal, setJournal] = useState([]);
  useEffect(() => {
    fetch(`${API}/jorn/getALL?limit=1000&sort=-year`)
      .then((res) => res.json())
      .then((res) => {
        setJournal(res.data);
        console.log(res.data);
      });
  }, []);
  function displayList(num) {
    const obj = document.getElementById("publish" + num);
    obj.style.display = obj.style.display === "flex" ? "none" : "flex";
  }
  return (
    <div className="journal-page">
      <div className="header">Journal Papers</div>
      <div className="journals">
        {journal.map((data, index) => {
          return (
            <div className="journal-data " data-index={index} >
              {data.year >= 2020 ? (
                <>
                <div
                    className="year data-list"
                    onClick={() => displayList(index)}
                  >
                    <p>Publications {data.year}</p>{" "}
                    <FontAwesomeIcon icon={faArrowDown} />
                  </div>
                  <ul className="w-100" id={`publish${index}`}>
                    {data.publications.slice(0).reverse().map((pub, index) => {
                      return (
                        <div className="research-card w-90" key={index}>
                          <div className="research-data w-100">
                            <div className="w-10">
                            <img src={Image} alt=""  />
                            </div>
                            <p className="dotted-desc w-90">{pub.description}</p>
                            <a href={pub.link} target="_block">
                              Read More
                            </a>
                          </div>
                        </div>
                      );
                    })}
                  </ul>


                </>
              ) : (
                
                <>
                  {/*<div
                    className="year data-list"
                    onClick={() => displayList(index)}
                  >
                    <p>Publications {data.year}</p>{" "}
                    <FontAwesomeIcon icon={faArrowDown} />
                  </div>
                  <ul id={`publish${index}`}>
                    {data.publications.map((pub, index) => {
                      return (
                        <li className="li">
                          <p>{pub.description}</p>
                          <a href={pub.link} target="_block">
                            Read More
                          </a>
                          <hr />
                        </li>
                      );
                    })}
                  </ul>*/}
                </>
              )
              }
            </div>
          );
        })}
      </div>
    </div>
  );
}
