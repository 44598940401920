import React from "react";
import './contact.css';
import {faMapLocation , faEnvelope , faMobile ,faPlay} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import arrow from '../../images/arrow.png'
export default function Contact(){
    return (
        <>
            <p className = 'header contact-data'>How To Find Us</p>
            <div className="contact">
                <div className="d-flex">

                    <div className = 'location-contact'>
                        <div className='icon-design'><FontAwesomeIcon icon={faMapLocation} className = 'icon'/></div>
                        <div className ='data'>
                            <h2>Location</h2>
                            <p>  Faculty of Computers & Artificial Intelligence</p>
                            <p>  Cairo University</p>
                            <p> 5 Ahmed Zewal St., Orman, Giza,</p>
                            <p>office box# 12613</p>
                        </div>
                    </div>
                    <div className = 'location-contact'>
                        <div className='icon-design'><FontAwesomeIcon icon={faEnvelope} className = 'icon'/></div>
                        <div className ='data'>
                            <h2>Email</h2>
                            <p>srge1964@gmail.com</p>
                        </div>
                    </div>
                    <div className = 'location-contact'>
                        <div className='icon-design'><FontAwesomeIcon icon={faMobile} className = 'icon'/></div>
                        <div className ='data'>
                            <h2>Call</h2>
                            <p>+0201114567956</p>
                        </div>
                    </div>
                    <div className = 'location-contact'>
                        <div className='icon-design'><FontAwesomeIcon icon={faPlay} className = 'icon'/></div>
                        <div className ='data'>
                            <h2>Watch More.</h2>
                            <a href="https://www.youtube.com/@SRSEGSchool" style={{textWrap:'wrap'}}>https://www.youtube.com/@SRSEGSchool</a>
                        </div>
                    </div>
                </div>

        </div>
        </>
        
    )
}