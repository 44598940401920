import axios from "axios";
import React, { useState, useEffect } from "react";
import './bookchapter.css'
import Pagination from "../pagination/pagination";
import { API } from "../../const";
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import TextBlock from '../truncateText/TruncateText'
export default function Chapters() {
    window.scrollTo(0, 0)
    const [chapters, setChapters] = useState([]);
    const [Loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [chaptersPerPage] = useState(5);
    useEffect(() => {
        const fetchChapters = async () => {
            setLoading(true);
            const { data } = await axios.get(`${API}/bookChapters/getAll?limit=300`);
            setChapters(data.data);
            setLoading(false);
        }
        fetchChapters();
    }, []);
    const indexOfLastPage = currentPage * chaptersPerPage;
    const indexOfFirstPage = indexOfLastPage - chaptersPerPage;
    const currentChapters = chapters.slice(indexOfFirstPage, indexOfLastPage);
    const paginate = PageNumber => setCurrentPage(PageNumber)

    return (
        (Loading) ? <p>Loading........</p> :
            <div className='chapters'>
                <div className="books">
                    {
                        currentChapters.slice(0).reverse().map((chapter, index) => {
                            return (
                                <div className="book-data" key={index}>

                                    <div className="book-header">
                                        <h2>{chapter.name}</h2>
                                    </div>
                                    <br />

                                    <Card>
                                        <Card.Text style={{padding:'20px'}}>
                                            <div className="row">

                                                {
                                                    (chapter.photo) ? <img className="img1" src={chapter.photo} alt='book' /> : <></>
                                                    
                                                }
                                                <div className="all-chapters">
                                                    {
                                                        chapter.allChapters.slice(0).reverse().map((des, index) => {
                                                            return (
                                                                <div key={index}>
                                                                    <div className="chapter-data">
                                                                        <div className="index">{index + 1}</div>
                                                                        <div className="desc">
                                                                            <p>{des.des}</p>
                                                                            {
                                                                                (des.getChapter) ? <a href={des.getChapter} target="_blank">Read More</a> : <></>
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                </div>

                                                            )
                                                        })
                                                    }

                                                </div>
                                            </div>
                                        </Card.Text>
                                    </Card>

                                </div>
                            )
                        })
                    }
                </div>
                <Pagination chaptersPerPage={chaptersPerPage} totalChapters={chapters.length} paginate={paginate} />
            </div>
    )
}