import React, { useEffect, useState } from 'react'
import Pagination from "../pagination/pagination";
import './socialActivities.css'
import { API } from '../../const';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import axios from 'axios';
import Event from '../eventspage/eventspage'
import TextBlock from '../truncateText/TruncateText'
//import './eventpage.css';
import ListGroup from 'react-bootstrap/ListGroup';
const SocialActivities = () => {
    const [activities, setActivities] = useState([]);
    window.scrollTo(0,0)
    const [Loading , setLoading] = useState(false);
    const [currentPage , setCurrentPage] = useState(1);
    const [activityPage] = useState(6);
     window.scrollTo(0, 0)
    const [research, setResearch] = useState([]);


    useEffect(() => {
        const fetchEvents = async () => {
            const res = await axios.get(`${API}/event/getAll?limit=100`);
            setResearch(res.data.data);
        }
        fetchEvents();
    }, []);


    function getTimeDetails(date) {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
        const mo = new Date(date);
        const day = mo.getDay();
        const month = months[mo.getMonth()];
        const year = mo.getFullYear();
        const hour = mo.getHours();
        return { day, month, hour,year };
    }
    
    const indexOfLastPage = currentPage * activityPage;
    const indexOfFirstPage = indexOfLastPage - activityPage;
    const currentactivities = activities.slice(indexOfFirstPage, indexOfLastPage);
    const paginate = PageNumber=> setCurrentPage(PageNumber)
    useEffect(() => {
        const fetchActivities = async () => {
            const res = await axios.get(`${API}/socialActivities/getAll?limit=1000`);
            setActivities(res.data.data);
        }
        fetchActivities();
    }, []);


    return (

         (Loading)?<p>Loading.....</p>:
        <div className ="books-page" style={{minHeight: '95vh',}}>
            <div className = 'header'>Events and social activities</div>
            <div className = 'all-books'>
                {
                    currentactivities.slice(0).reverse().map((activity , index)=>{
                        return(
                            <div className='book' key={index}>
                                <img src={activity.photo} alt='bookImage'/>
                                <div className="books-data">
                                    <p className='name'>{activity.name}</p>
                                    <p>
                                        <TextBlock
                                            text={activity.des}
                                            maxLength={70} // Optional prop to set the maximum length of the text
                                        /> 
                                    </p>
                                </div>
                            </div>
                        )
                    })
                    
                }
                {research.slice(0).reverse().map((data, index) => {
                    let details = getTimeDetails(data.date);
                    console.log(details)
                    return(
                            <div className='book' key={index} style={{maxWidth:'100%',direction: 'rtl'}}>
                                <img src={data.photo} alt='bookImage'/>
                                <div style={{textAlign:'justify'}}>
                                <p className='name' style={{ fontSize: '23px' }}>{data.title}</p>
                                <div style={{    fontSize: '20px',fontWeight: '600'}}>
                                    <p>
                                        {data.description}
                                        {/*<TextBlock
                                            text={data.description}
                                            maxLength={70} // Optional prop to set the maximum length of the text
                                        /> */}
                                    </p>
                                    <div style={{ fontSize: '20px',color:'gray' ,fontWeight: '100'}}>
                                        <p>{details.day + 1} {details.month} {details.year}</p>
                                        {/*<p>Duration {details.hour} h</p>*/}
                                        <p> Location : {data.location}</p>
                                    </div>

                                </div>
                               
                                   <Card.Body className="books-data">
                                        {data.pdfFile ? (
                                            <p className="mx-2" style={{ textAlign: 'left' }}>
                                                <a href={data.pdfFile} target="_blank" rel="noreferrer" download>Download Pdf</a>
                                            </p>
                                    ) : (
                                            ""
                                            //<p className="mx-2" style={{ textAlign: 'left' }}>
                                            //    PDF file not available
                                            //</p>
                                        )}
                                    </Card.Body>

                                
                                </div>
                            </div>
                        )
                })}
            </div>
            <Pagination chaptersPerPage={activityPage} totalChapters={activities.length} paginate={paginate}/>
        </div>
    )
}

export default SocialActivities