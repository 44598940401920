import './App.css';
import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import CustomNavbar from './components/navbar/navbar';
import HomePage from './components/homepage/homepage';
import MembersPage from './components/memberspage/memberspage';
import Publications from './components/Publications/Publications';
import EventsPage from './components/eventspage/eventspage';
import Footer from './components/footer/footer';
import Research from './components/eachresearch/eachresearch';
import About from './components/about/about';
import Contact from './components/contact/contact';
import Books from './components/books/books';
import Chapters from './components/bookchapter/bookchapter';
import Journal from './components/journal/journal';
import International from './components/international/international';
import National from './components/national/national';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Top from './components/btnToTop/totop'
import SocialActivities from './components/socialActivities';
import News from './components/news';
import Logo from './images/logo.png'
function App() {
  useEffect(() => {
    document.title = 'SRSEG';
        const link = document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href =Logo ;  // Replace with the path to your icon
    
        // Remove the existing favicon, if any
        const existingFavicon = document.querySelector('link[rel="shortcut icon"]');
        if (existingFavicon) {
          document.head.removeChild(existingFavicon);
        }
    
        // Append the new link element to the head
        document.head.appendChild(link);

  }, []);
  window.addEventListener('scroll', animation);
  function animation() {
    var animate = document.querySelectorAll('.animate');
    for (let i = 0; i < animate.length; i++) {
      var windowhight = window.innerHeight;
      var goToTop = animate[i].getBoundingClientRect().top;
      var point = 100;
      if (goToTop < windowhight + point) {
        animate[i].classList.add('active');
      } else {
        animate[i].classList.remove('active');
      }
    }
  }

  return (
    <Router>
      <CustomNavbar />
      <Switch>
        <Route path='/SRSEG' exact component={HomePage} />
        <Route path='/' exact component={HomePage} />
        <Route path='/books' exact component={Books} />
        <Route path='/bookchapters' exact component={Chapters} />
        <Route path='/journal' exact component={Journal} />
        <Route path='/intconf' exact component={International} />
        <Route path='/national' exact component={National} />
        <Route path='/members' exact component={MembersPage} />
        <Route path='/Publications' exact component={Publications} />
        <Route path='/events' exact component={EventsPage} />
        <Route path='/about' exact component={About} />
        <Route path='/news' exact component={News} />
        <Route path='/contact' exact component={Contact} />
        <Route path='/socialActivities' exact component={SocialActivities} />
        <Route path='/research/:id' exact component={Research} />
        <Route path='*' exact component={HomePage} />
      </Switch>
      <Top />
      <Footer />
    </Router>
  );
}

export default App;
