import React, { useState, useEffect } from "react";
import './slideimage.css';
// import one from '../../images/1.jpg';
// import two from '../../images/2.jpg';
// import three from '../../images/3.jpg';
// import four from '../../images/4.jpg';
import Image1 from '../../images/slider1.jpg';
import Image2 from '../../images/slider2.jpg';
import Image3 from '../../images/slider3.jpg';
import Image4 from '../../images/slider4.jpg';
import Image5 from '../../images/slider5.jpg';
import Image6 from '../../images/slider6.jpg';
import Image7 from '../../images/slider7.jpg';
import Image8 from '../../images/slider8.jpg';


export default function ImageSlider() {
    const images = [Image1, Image2, Image3, Image4,Image5,Image6,Image7,Image8];
    const [currentIndex, setCurrentIndex] = useState(0);
    useEffect(() => {
        const timer = setTimeout(() => {
            goToNext()
        }, 5000)
        return () => clearTimeout(timer)
    }, [currentIndex])
    const leftArrow = {
        position: 'absolute',
        top: '50%',
        transform: 'translate(0,-50%)',
        left: '32px',
        fontSize: '30px',
        color: '#eee',
        zIndex: 1,
        cursor: 'pointer',
        fontWeight: 'bold',
    }
    const rightArrow = {
        position: 'absolute',
        top: '50%',
        transform: 'translate(0,-50%)',
        right: '32px',
        fontSize: '30px',
        color: '#eee',
        zIndex: 1,
        cursor: 'pointer',
        fontWeight: 'bold',
        transition: '0.7s ease-in'

    }
    const dotsStyle = {
        color: '#BDA175',
        fontSize: '60px',
        display: 'flex',
        justifyContent: 'center',
        zIndex: '2',
        position: 'absolute',
        bottom: '2%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        cursor: 'pointer',
    }
    const dots = {
        padding: '0 8px',
    }
    const goToPrevious = () => {
        const isFirst = currentIndex === 0;
        const newIndex = isFirst ? images.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    }
    const goToNext = () => {
        const isLast = currentIndex === images.length - 1;
        const newIndex = isLast ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    }
    const goToSlide = (index) => {
        setCurrentIndex(index);
    }

    return (
        <div className="slider" style={{ backgroundImage: `url('${images[currentIndex]}')` }}>
            <div className="slides">
                <div style={leftArrow} onClick={goToPrevious}>{'❮'}</div>
                <div style={rightArrow} onClick={goToNext}>{'❯'}</div>
                <div className="slide" ></div>
                <div style={dotsStyle}>
                    {
                        images.map((slide, index) => {
                            return (
                                <div key={index} style={dots} onClick={() => goToSlide(index)}>{index === currentIndex ? '•' : '◦'}</div>
                            );
                        })
                    }
                </div>
            </div>
            <div className="home">
                <p className="title">Welcome To SRSEG</p>
                <p>Scientific Research School of Egypt (SRSEG)</p>
                <a href='#page'>Get Started</a>
            </div>
        </div>

    )
}