import React, { useState, useEffect } from 'react';

export default function TextBlock({ text, maxLength = 60 }) {
  const [truncatedText, setTruncatedText] = useState('');
  const [showTruncatedText, setShowTruncatedText] = useState(true);
  const [isReadMore, setIsReadMore] = useState(false);

  useEffect(() => {
    const truncateText = (text, maxLength) => {
      if (text.length <= maxLength) {
        setShowTruncatedText(false);
        return text;
      }
      setShowTruncatedText(true);
      return text.slice(0, maxLength) + '...';
    };

    const truncated = truncateText(text, maxLength);
    setTruncatedText(truncated);
    setIsReadMore(truncated !== text);
  }, [text, maxLength]);

  const shouldShowReadMore = showTruncatedText && isReadMore;

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
    setShowTruncatedText(!showTruncatedText);
  };

  return (
    <div className="text-block" onClick={toggleReadMore}>
      {showTruncatedText ? <div dangerouslySetInnerHTML={{ __html: truncatedText }} /> : <div dangerouslySetInnerHTML={{ __html: text }} /> }
      {shouldShowReadMore && (
        <span className="read-more" style={{color:'blue',cursor:'pointer'}}>{isReadMore ? 'Read More' : 'Show Less'}</span>
      )}
    </div>
  );
}
