import React, { useEffect, useState } from "react";
import './national.css';
import { API } from "../../const";
export default function NationalContent() {
  window.scrollTo(0, 0);
  const [natio , setNational] = useState([]);
  useEffect(()=>{
    fetch(`${API}/national/getALL?limit=1000`)
    .then((res)=>res.json())
    .then((res)=>{
        setNational(res.data);
});},[]);
  return (
    <div className="national-page">
      <div className="nationals">
      <div className="container">
      <div className="header">Conference papers</div>

          <ul>
            {
              natio.map((data , index)=>{
                return <li><p>{data.des}</p></li>
                
              })
            }
          </ul>
          </div>
      </div>
    </div>
  );
}
