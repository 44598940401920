import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./navbar.css";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../../images/logo.png'

export default function CustomNavbar() {
  const [active, setActive] = useState(false);

  return (
    <Navbar collapseOnSelect expand="lg"
      className="nav justify-content-between" id="nav"
      bg="#3d6776" data-bs-theme="dark" sticky="top">
      <Container fluid="md" className="justify-content-between">

        <Navbar.Brand href="/" className='logo'>
          <img
            alt=""
            src={logo}
            width="50"
            height="50"
            className="d-inline-block align-top"
          />{' '}
          <div className="text-left">
            Scientific Research 
            <div className="text-left"> School of Egypt (SRSEG)</div>
          </div>
        </Navbar.Brand>
       
        <Navbar.Toggle aria-controls="responsive-navbar-nav"/>

        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end" style={{ flexGrow: 0 }}>
          <Nav className="me-auto">
            <Nav.Link as={NavLink} to="/home" className="nav-link" href="/">Home</Nav.Link>
            <Nav.Link as={NavLink} to="/members" className="nav-link" href="/members">Members</Nav.Link>
            <Nav.Link as={NavLink} to="/Publications" className="nav-link" href="/Publications">Publications</Nav.Link>
            <Nav.Link as={NavLink} to="/socialActivities" className="nav-link" href="/socialActivities">Events and social activities</Nav.Link>
            <Nav.Link as={NavLink} to="/news"className="nav-link" href="/news">News</Nav.Link>
            <Nav.Link as={NavLink} to="/contact" className="nav-link" href="/contact">Contact Us</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}





















//    <nav className="nav" id="nav">
//      <NavLink to="/" onClick={() => { setActive(false) }}>
//        <div className='logo'>
//        <img src={logo} style={{ width: '40px' }} alt='logo' />
//        <div>
//          <div className="text-left">
//          Scientific Research 
//          </div>
//          <div className="text-left"> School of Egypt (SRSEG)</div>
//        </div>

//        </div>
//      </NavLink>
//      <ul className={`menu ${active ? "active" : "notactive"}`}>

//        <li className="nav-item" onClick={() => { active ? setActive(false) : setActive(true) }}>
//          <NavLink activeStyle={{ color: "#BDA175" }} to="/" exact className="nav-link">Home</NavLink>
//        </li>
//        <li className="nav-item" onClick={() => { active ? setActive(false) : setActive(true) }}>
//          <NavLink
//            activeStyle={{ color: "#BDA175" }}
//            to="/Publications"
//            className="nav-link">
//            Publications
//          </NavLink>
//        </li>  

        //<li className="nav-item" onClick={() => { active ? setActive(false) : setActive(true) }}>
        //  <NavLink
        //    activeStyle={{ color: "#BDA175" }}
        //    to="/members"
        //    className="nav-link">
        //    Members
        //  </NavLink>
        //</li>   

        //<li className="nav-item" onClick={() => { active ? setActive(false) : setActive(true) }}>
        //  <NavLink
        //    activeStyle={{ color: "#BDA175" }}
        //    to="/socialActivities"
        //    className="nav-link">
        //     Events and social activities
        //  </NavLink>
        //</li>
//{/* 
//        <li className="nav-item" onClick={() => { active ? setActive(false) : setActive(true) }}>
//          <NavLink
//            activeStyle={{ color: "#BDA175" }}
//            to="/events"
//            className="nav-link">
//            Events
//          </NavLink>
//        </li> */}
//        <li className="nav-item" onClick={() => { active ? setActive(false) : setActive(true) }}>
//          <NavLink
//            activeStyle={{ color: "#BDA175" }}
//            to="/news"
//            className="nav-link">
//            News
//          </NavLink>
//        </li>
//        <li className="nav-item" onClick={() => { active ? setActive(false) : setActive(true) }}>
//          <NavLink
//            activeStyle={{ color: "#BDA175" }}
//            to="/contact"
//            className="nav-link">
//            Contact Us
//          </NavLink>
//        </li>


//      </ul>
//      <div className={`${!active ? "nav-toggler" : "nav-toggler toggleIcon"}`} onClick={() => { active ? setActive(false) : setActive(true); }}>
//        <div className="line1"></div>
//        <div className="line2"></div>
//        <div className="line3"></div>
//      </div>
    //    </nav>