import React, { useState, useEffect } from "react";
//import './Publications.css'
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import { FaBookReader } from "react-icons/fa";
import { FaSwatchbook } from "react-icons/fa6";
import { IoIosJournal } from "react-icons/io";
import { FaPeopleGroup } from "react-icons/fa6";
import { RiAccountPinBoxFill } from "react-icons/ri";
import { API } from "../../const";
import logo from '../../images/logo.png'
import Books from "../books/books";
import Chapters from "../bookchapter/bookchapter";
import JournalContent from "../journal/journal"
import InternationalContent from "../international/international"
import NationalContent from "../national/national"
import CustomBreadcrumb from "../breadcrumb/breadcrumb"
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import TextBlock from '../truncateText/TruncateText'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
export default function Publications() {
    const [loading, setLoading] = useState(true);
    const [active, setActive] = useState('Books');

    window.scrollTo(0, 0)

    const types = [
        { title: "Books", icon: <FaBookReader />, component: <Books /> },
        { title: "Book Chapters", icon: <FaSwatchbook />,component: <Chapters />},
        { title: "Journal Papers", icon: <IoIosJournal />,component: <JournalContent />},
        { title: "International Conference papers", icon: <FaPeopleGroup />,component: <InternationalContent />},
        { title: "National Conference papers", icon: <RiAccountPinBoxFill />,component: <NationalContent /> },
    ];


    return (
        <>
           
             {/*<CustomBreadcrumb></CustomBreadcrumb>*/}
            <div className="researchs members-page">
                <div className="filter">
                    {types.map((item, index) => (
                        <button key={index} onClick={() => setActive(item.title)} className={`${active === item.title ? 'active' : ''}`}>
                            {(item.icon)}
                            <span>{(item.title)}</span>
                        </button>
                    ))}
                </div>

                <div className="fullmembers">          
                    { types.find(item => item.title === active)?.component}
                </div>
            </div >
        </>
    );
}
