import React, { useState, useEffect } from "react";
import './memberspage.css'
import { API } from "../../const";
import logo from '../../images/logo.png'
import Container from 'react-bootstrap/Container';
import { FaBookReader } from "react-icons/fa";
import CustomBreadcrumb from "../breadcrumb/breadcrumb"
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import TextBlock from '../truncateText/TruncateText'

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
export default function MembersPage() {
    const [loading, setLoading] = useState(true);
    const [active, setActive] = useState('chairs');
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
    window.scrollTo(0, 0)

    function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
    }

    const [members, setMembers] = useState([]);

    useEffect(() => {
        setLoading(true);
        fetch(`${API}/member/getAll?limit=1000&type=${active}`)
            .then((res) => res.json())
            .then((res) => {
                setMembers(res.data);
                setLoading(false);
            })
    }, [active])


    // const types = ['chairs', 'Vice Chair', 'senior', 'junior', 'undergrade', 'past member'];
    const types = [
        { title: "chairs",icon: <FaBookReader />, img: "/images/filter/speech.png" },
        { title: "Vice Chair",icon: <FaBookReader />, img: "/images/filter/mayor.png" },
        { title: "senior",icon: <FaBookReader />, img: "/images/filter/student.png" },
        { title: "junior",icon: <FaBookReader />, img: "/images/filter/graduating-student.png" },
        { title: "undergrade",icon: <FaBookReader />, img: "/images/filter/graduation.png" },
        { title: "past",icon: <FaBookReader />, img: "/images/filter/pastMember.png" },
    ]


    return (
        <>
            {/*<CustomBreadcrumb></CustomBreadcrumb>*/}
            <div className="researchs members-page">
                <div className="filter">
                    {types.map((item, index) => (
                        <button key={index} onClick={() => setActive(item.title)} className={`${active === item.title ? 'active' : ''}`}>
                            <img src={item.img} alt="" />
                            <span>{index==4 ?(
                            " Postgrade Student Member"
                            ): ( item.title + " Member")}</span>
                        
                        </button>
                    ))}
                </div>
                <div className="fullmembers">
                    {loading ?
                        <div className="loading">
                            <img src={logo} alt="" />
                            
                        </div>
                        :
                        members.map((data, index) => {
                            return (
                                <Card style={{ width: '100%',padding: '20px',margin: '20px 0' }}>
                                    <Row >
                                        <Col md={12} lg={3} >
                                            <Row>
                                                <Col xs={12} md={12} style={{ width: '100%',display: 'flex',alignItems: 'center',justifyContent: 'center' }}>
                                                 
                                                    <Image src={data.photo} style={{ width: '200px',height: '260px',borderRadius:'10px' }} />    
                                               
                                                </Col>
                                                <Col xs={12} md={12} style={{ width: '100%',display: 'flex',alignItems: 'center',justifyContent: 'center',padding: '10px 0' }}>
                                                    <Card.Body style={{ width: '100%',textAlign:'left',fontSize:'15px'}}>
                                                        <Card.Title style={{ fontSize:'15px'}}>DR. {data.name}</Card.Title>
                                                        <Card.Text style={{ fontSize:'15px',color:'gray',}}>
                                                            {data.affiliation}
                                                        </Card.Text>
                                                        <a href={`mailto:${data.email}`}>{data.email}</a><br/>
                                                    </Card.Body>
                                                </Col>
                                            </Row> 
                                        </Col>
                                        <Col md={12} lg={9}>
                                            <Card.Text style={{textAlign:'justify'}}>
                                                <TextBlock
                                                        text={data.description}
                                                        maxLength={900} // Optional prop to set the maximum length of the text
                                                    /> 
                                            </Card.Text>
                                        </Col>
                                    </Row>
                                </Card>
                                //<div className='team-card' key={index}>
                                //    <div className="w-30">
                                //        <Row>
                                //            <Col xs={6} md={4}>
                                //            <Image src={data.photo} className="image-member" roundedCircle />
                                //            </Col>
                                //        </Row>
                                //        {/*<img src={data.photo}  className="image-member" alt='member' />*/}
                                //        <div className="team-name"> {data.name}</div>
                                //        <div className="team-job">{data.affiliation}</div>
                                //        <div className="team-job">{data.researchInterest}</div>
                                //        {/* <div className="team-job">{data.degree}</div> */}
                                //        <a href={`mailto:${data.email}`} className="color-link">{data.email}</a>
                                //    </div>
                                //    <div className="team-data">
                                //            {data.description}
                                //            {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa assumenda, itaque totam neque quas explicabo minus labore voluptatibus aperiam! Exercitationem veritatis tempore architecto vitae quis magnam voluptatibus explicabo quam amet.    */}
                                //            {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa assumenda, itaque totam neque quas explicabo minus labore voluptatibus aperiam! Exercitationem veritatis tempore architecto vitae quis magnam voluptatibus explicabo quam amet.    */}
                                //        {/* <a href={`mailto:${data.email}`}>Email</a> */}
                                //    </div>
                                //</div>
                            );
                        })
                    }
                </div>

            </div >
         
        </>
    );
}