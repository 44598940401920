import ImageSlider from '../someComponents/sliderimage'
import './homepage.css'
import Ellipse from '../../images/Ellipse.png'
import Ellipse2 from '../../images/Ellipse2.png'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default function HomePage() {
    // window.scrollTo(0, 0)
    return (
        <>
            <ImageSlider />
            {/* <div className="information" id="page">
                <div className="header-data">
                </div>
            </div> */}
            <div className='information siteDesc'>
                <div className="data first-sec desc">
                    <h1>
                        {/* Scientific Research School of Egypt (SRSEG) */}
                        
                        The Scientific Research School of Egypt (SRSEG): Pioneering Computer Science Excellence
                                                </h1>
                    {/* <p>
                        <img className='Ellipse mx-2' src={Ellipse} />
                        Pioneering Computer Science Excellence Preface and Welcoming!
                        Pioneering Computer Science Excellence
                    </p> */}
                    <p>
                        <img className='Ellipse mx-2' src={Ellipse} />
                        {/* Welcome to the Scientific Research School of Egypt (SRSEG) Website, a dynamic hub of innovation and collaboration in computer science. The roots of our journey can be traced back to 2002, with the inception of the Abo Research Lab. Originally comprising undergraduate and postgraduate students under the guidance of Professor Aboul Ella Hassanien, SRSEG has evolved into a prestigious entity over the past two decades. */}
                        Welcome to the Scientific Research Group in Egypt (SRSEG), formerly called Scientific Research Group in Egypt (SRGE), a dynamic hub of innovation and collaboration in computer science. The roots of our journey can be traced back to 2002, with the inception of the Abo Research Lab. Originally comprising undergraduate and postgraduate students under the guidance of Professor Aboul Ella Hassanien, SRSEG has evolved into a prestigious entity over the past two decades. The new name SRSEG was coined in the beginning of 2024.

                    </p>
                    <p>
                        <img className='Ellipse mx-2' src={Ellipse} />
                        SRSEG is committed to excellence, fostering a community of scholars that transcends institutional boundaries. From its modest beginnings, the research group has grown to encompass students and researchers from various Egyptian universities, and it has extended its reach to include contributors from several Arab countries.

                        {/* SRSEG is committed to excellence, fostering a community of scholars that transcends institutional boundaries. From its modest beginnings, the research group has grown to encompass students and researchers from various Egyptian universities, and it has extended its reach to include contributors from several Arab countries. */}
                    </p>
                    <p>
                        <img className='Ellipse mx-2' src={Ellipse} />
                        At SRSEG, we believe in the power of evaluation and the significance of continuous follow-up with our members. This commitment ensures that our research remains at the forefront of advancements in computer science. Our collaborative spirit extends beyond geographical borders, facilitating an exchange of ideas that enriches the intellectual landscape of the entire region.

                        {/* At SRSEG, we believe in the power of evaluation and the significance of continuous follow-up with our members. This commitment ensures that our research remains at the forefront of advancements in computer science. Our collaborative spirit extends beyond geographical borders, facilitating an exchange of ideas that enriches the intellectual landscape of the entire region. */}
                    </p>
                    <p>
                        <img className='Ellipse mx-2' src={Ellipse} />
                        As SRSEG looks back on two decades of achievements, our mission remains clear—to inspire and cultivate the next generation of innovators in computer science. We are dedicated to creating a conducive environment for the exploration of cutting-edge ideas, the development of impactful projects, and the organization of conferences and workshops that contribute to the wider scientific community.

                        {/* As SRSEG looks back on two decades of achievements, our mission remains clear—to inspire and cultivate the next generation of innovators in computer science. We are dedicated to creating a conducive environment for the exploration of cutting-edge ideas, the development of impactful projects, and the organization of conferences and workshops that contribute to the wider scientific community. */}
                    </p>
                    <p>
                        <img className='Ellipse mx-2' src={Ellipse} />
                        In alignment with Egypt's Vision 2030 and the global pursuit of Sustainable Development Goals SRSEG, the SRSEG emerges as a key player in shaping the nation's technological landscape and fostering sustainable growth.

                        {/* In alignment with Egypt's Vision 2030 and the global pursuit of Sustainable Development Goals (SDGs), the Scientific Research School of Egypt (SRSEG) emerges as a key player in shaping the nation's technological landscape and fostering sustainable growth. */}
                    </p>
                    <p>
                    <img className='Ellipse mx-2' src={Ellipse} />
                    As Egypt sets its sights on becoming a knowledge-based economy, SRSEG stands at the forefront of driving innovation in computer science and technology. By cultivating a culture of research excellence and nurturing the brightest minds, SRSEG contributes significantly to the development of cutting-edge technologies that propel the nation toward economic prosperity.

                        {/* As Egypt sets its sights on becoming a knowledge-based economy, SRSEG stands at the forefront of driving innovation in computer science and technology. By cultivating a culture of research excellence and nurturing the brightest minds, SRSEG contributes significantly to the development of cutting-edge technologies that propel the nation toward economic prosperity. */}
                    </p>
                    <p>
                    <img className='Ellipse mx-2' src={Ellipse} />
                    At the heart of SRSEG mission lies a commitment to education and capacity building. By involving undergraduate and postgraduate students from various Egyptian universities, SRSEG not only enriches the academic experience but also prepares the next generation of leaders and innovators in computer science. This emphasis on education directly aligns with the SDG goal of quality education (SDG 4), ensuring that knowledge and skills are accessible to all.

                        {/* At the heart of SRSEG's mission lies a commitment to education and capacity building. By involving undergraduate and postgraduate students from various Egyptian universities, SRSEG not only enriches the academic experience but also prepares the next generation of leaders and innovators in computer science. This emphasis on education directly aligns with the SDG goal of quality education (SDG 4), ensuring that knowledge and skills are accessible to all. */}
                    </p>
                    <p>
                    <img className='Ellipse mx-2' src={Ellipse} />
                    Join us on this exciting journey as we continue to push the boundaries of knowledge and forge lasting connections among researchers, students, and institutions. SRSEG invites you to join our vibrant community, where ideas flourish and collaborations thrive.

                        {/* Join us on this exciting journey as we continue to push the boundaries of knowledge and forge lasting connections among researchers, students, and institutions. SRSEG invites you to join our vibrant community, where ideas flourish and collaborations thrive. */}
                    </p>


                </div>



            </div>
            <div className="information siteDesc second-sec">
                <div className="data  desc">
                    <h4 className='subTitle my-3'>SRSEG Mission Statement</h4>
                    <p>
                        <img className='Ellipse mx-2' src={Ellipse2} />
                        {/* SRSEG mission is to advance the frontiers of computer science through unwavering commitment to excellence, innovation, and collaboration. We aspire to be a catalyst for positive change, shaping the future of technology and contributing to Egypt's progress and Sustainable Development Goals.*/}
                        SRSEG mission is to advance the frontiers of computer science through unwavering commitment to excellence, innovation, and collaboration. We aspire to be a catalyst for positive change, shaping the future of technology and contributing to Egypt's progress and Sustainable Development Goals
                    </p> 
                    <h4 className='subTitle my-3'>SRSEG Vision Statement</h4>
                    <p>
                    <img className='Ellipse mx-2' src={Ellipse2} />
                        {/* SRSEG vision is to be a catalyst for pioneering breakthroughs, reshaping the landscape of computer science and influencing positive change on a worldwide scale. */}
                        SRSEG vision is to be a catalyst for pioneering breakthroughs, reshaping the landscape of computer science and influencing positive change on a worldwide scale.
                        </p>

                </div>
                <h2 className='color-white mt-40'>The Main Objectives of SRSEG</h2>
                <div className="data list Objectives ">     
                        <p className='elements'>
                            <span className='ellisps'>1</span>
                            Advanced cutting-edge Research: Conduct innovative and impactful research in various domains of computer science.
                        </p>
                        <p className='elements'>
                        <span className='ellisps'>2</span>
                        Foster Academic Excellence: Provide a platform for undergraduate and postgraduate students and researchers to engage in high-quality research under the guidance of experienced mentors.
                        </p>
                        <p className='elements'>
                        <span className='ellisps'>3</span>

                        Facilitate Interdisciplinary Collaboration: Encourage collaboration between researchers from different disciplines, fostering a multidisciplinary approach to problem-solving.
                        </p>
                        <p className='elements'>
                        <span className='ellisps'>4</span>

                        Promote Inclusivity and Diversity: Create an inclusive environment that welcomes participants from various Egyptian universities and extends collaboration to researchers in worldwide.
                        </p>
                        <p className='elements'>
                        <span className='ellisps'>5</span>

                        Contribute to Sustainable Development: Apply research findings to address real-world challenges and contribute to sustainable development goals.
                        </p>
                        <p className='elements'>
                        <span className='ellisps'>6</span>

                        Engage with the Community: Demonstrate the societal impact of computer science research and foster positive relationships with the broader community.
                        </p>

                </div>
            </div>


            <div className='information siteDesc'>
                    <h1>The Main Fields of Research for SRSEG</h1>
                <div className="data desc Objectives">
                    <p className='elements'>
                            <span className='ellisps-fields'>1</span>
                            Artificial Intelligence (AI)
                    </p>
                    <p className='elements'>
                            <span className='ellisps-fields'>2</span>
                            Explainable Artificial Intelligence
                    </p>
                    <p className='elements'>
                            <span className='ellisps-fields'>3</span>
                            Cybersecurity and Privacy
                    </p>
                    <p className='elements'>
                            <span className='ellisps-fields'>4</span>
                            Digital Twining
                    </p>
                    <p className='elements'>
                            <span className='ellisps-fields'>5</span>
                            Natural Language Processing (NLP)
                    </p>
                    <p className='elements'>
                            <span className='ellisps-fields'>6</span>
                            Biomedical Informatics
                    </p>
                    <p className='elements'>
                            <span className='ellisps-fields'>7</span>
                            Quantum Computing
                    </p>
                    <p className='elements'>
                            <span className='ellisps-fields'>8</span>
                            Social Network Analysis and Computational Social Science
                    </p>
                    <p className='elements'>
                            <span className='ellisps-fields'>9</span>
                            Computational Economics
                    </p>
                    <p className='elements'>
                            <span className='ellisps-fields'>10</span>
                            Generative Artificial Intelligence (GAI)
                    </p>
                    <p className='elements'>
                            <span className='ellisps-fields'>11</span>
                            Data Science and Big Data Analytics
                    </p>
                    <p className='elements'>
                            <span className='ellisps-fields'>12</span>
                            Internet of Things (IoT), Cloud Computing and Fog Computing
                    </p>
                    <p className='elements'>
                            <span className='ellisps-fields'>13</span>
                            Computer Vision and Image Processing
                    </p>
                    <p className='elements'>
                            <span className='ellisps-fields'>14</span>
                            Blockchain and Distributed Ledger Technologies
                    </p>
                    <p className='elements'>
                            <span className='ellisps-fields'>15</span>
                            Robotics and Autonomous Systems
                    </p>
                    <p className='elements'>
                            <span className='ellisps-fields'>16</span>
                            Augmented and Virtual Reality (AR/VR) and Metaverse
                    </p>

                    <p className='elements'>
                            <span className='ellisps-fields'>17</span>
                            Sustainable Computing for Energy, Environment and Climate
                    </p>
                    <p className='elements'>
                            <span className='ellisps-fields'>18</span>
                            Drug Discovery and Development
                    </p>
                </div>
            </div>
        </>
    )
}