import React, { useEffect, useState } from 'react'
import './news.css'
import axios from 'axios';
import { API } from '../../const';
import moment from 'moment';
import TextBlock from '../truncateText/TruncateText'
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
const News = () => {


    window.scrollTo(0, 0);
    const [news, setNews] = useState([]);
    useEffect(() => {
        const fetchNews = async () => {
            const res = await axios.get(`${API}/news/getAll?limit=1000`);
            setNews(res.data.data);
        }
        fetchNews();
    }, []);

     const ReadFunc=(id)=>{
            let check= document.getElementById(id).querySelector(".content");
            let readMore =document.getElementById(id).querySelector(".read-more");
            let link =document.getElementById(id).querySelector(".link");
            if(check.classList.contains("active") ){
                check.classList.remove("active")
                link.classList.remove("active")
                
                readMore.innerHTML="Read More"
                
            }else{
                check.classList.add("active")
                link.classList.add("active")
                readMore.textContent="Read Less"

            }
        
        };
        
    return (
        <div>
            <div className="header">News</div>
            <div className="fullNews">
                {news.slice(0).reverse().map((item, index) => {
                    return (
                        <div className="news" key={index} id={index}>
                            <div className='newsTitle'>
                                <p style={{color: 'black'}}>{item.Title}</p>
                            </div>
                            <div className='newsDate'>
                                <h4>{moment(item.PublicationDate).format('DD')}</h4>
                                <h4>{moment(item.PublicationDate).format('MMM')}</h4>
                                <h4>{moment(item.PublicationDate).format('YYYY')}</h4>
                                {/*<h6>{moment(item.PublicationDate).format('YYYY')}</h6>*/}  
                            </div>

                            <div className='newsBody'>
                                <Row>
                                    <Col md={4} sm={12}>
                                        <div className="leftbody">
                                            <div>
                                                <img src={item.photo} style={{ borderRadius: '10px' }} alt="" /> 
                                            </div>
                                            <div style={{textAlign:'end'}}>
                                                <span>{item.Category}</span>
                                            </div>
                                        </div>
                                    </Col>
                                     <Col md={8} sm={12}>
                                        <div className="rightbody">
                                            <TextBlock
                                                text={item.Content}
                                                maxLength={700} // Optional prop to set the maximum length of the text
                                            />
                                        </div>
                                    </Col>
                                </Row>

                            </div>
                            {/*<div className='d-flex'>
                                <div className='read-more'><a href={item.link} target='_blank' >{item.link}</a></div>
                            </div>*/}
                            <div className='d-flex'>
                                <button style={{padding:'10px',border:'none',borderRadius:'10px'}} className=''><a href={item.link} target='_blank' >{item.link}</a></button>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default News