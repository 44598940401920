import React, { useState, useEffect } from "react";
import './eventpage.css';
import axios from "axios";
import { API } from "../../const";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import TextBlock from '../truncateText/TruncateText'
export default function ResearchPage() {
    window.scrollTo(0, 0)
    const [research, setResearch] = useState([]);


    useEffect(() => {
        const fetchEvents = async () => {
            const res = await axios.get(`${API}/event/getAll?limit=100`);
            setResearch(res.data.data);
        }
        fetchEvents();
    }, []);


    function getTimeDetails(date) {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
        const mo = new Date(date);
        const day = mo.getDay();
        const month = months[mo.getMonth()];
        const hour = mo.getHours();
        return { day, month, hour };
    }
 return (
    <>
        {research.slice(0).reverse().map((data, index) => {
            let details = getTimeDetails(data.date);
            return (
                <Card key={index} style={{ width: '18rem' }}>
                    <Card.Img style={{ width: '18rem', height: '27rem' }} variant="top" src={data.photo} alt="eventImage" />
                    <Card.Body>
                        <Card.Title>{data.title}</Card.Title>
                        <Card.Text>
                            <TextBlock
                                text={data.description}
                                maxLength={40} // Optional prop to set the maximum length of the text
                            />
                        </Card.Text>
                         <ListGroup className="list-group-flush">
                            <ListGroup.Item>{details.day + 1} {details.month} {details.year}</ListGroup.Item>
                            <ListGroup.Item>Duration {details.hour} h</ListGroup.Item>
                            <ListGroup.Item> Location : {data.location}</ListGroup.Item>
                        </ListGroup>
            
                        <p  className="mx-2" style={{ textAlign: 'left' }}>
                            <a style={{color : 'blue'}}  href={data.pdfFile} target="_blank" rel="noreferrer"  download>Download Pdf</a>
                        </p>
                    </Card.Body>
                </Card>
            )
        })}
    </>
);

}

         
                    

                    
                    
                 