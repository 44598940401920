import React from "react";
import './footer.css'
export default function Footer(){
    return(
        <>
        <div className = "footer">
            <p className= "fo">@2024 SRSEG All Right Reserved - Devoloped by <a href="https://www.linkedin.com/in/khaled-elsaeed-abdelfattah-08a456215/" rel="noreferrer" target="_blank" className="devoloping_link">Eng.khaled Elsaeed</a> </p>
        </div>
        </>
    );
}
